import Comments from 'components/Comments';
import Translations from 'components/Translations';
import Attribution from 'components/Attribution';
import { ApiDesignPresentation } from 'components/react-query-api-design-lessons-learned';
import { RqToc } from 'components/rq-toc';
import * as React from 'react';
export default {
  Comments,
  Translations,
  Attribution,
  ApiDesignPresentation,
  RqToc,
  React
};