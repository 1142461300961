import Comments from 'components/Comments';
import Attribution from 'components/Attribution';
import Translations from 'components/Translations';
import Emph from 'components/Emph';
import Tweet, { AvatarTkDodo, TweetImage, QueryGGImage } from 'components/Tweet';
import * as React from 'react';
export default {
  Comments,
  Attribution,
  Translations,
  Emph,
  Tweet,
  AvatarTkDodo,
  TweetImage,
  QueryGGImage,
  React
};