import Comments from 'components/Comments';
import Translations from 'components/Translations';
import Attribution from 'components/Attribution';
import { TheBadPartsPresentation } from 'components/react-query-the-bad-parts';
import { RqToc } from 'components/rq-toc';
import * as React from 'react';
export default {
  Comments,
  Translations,
  Attribution,
  TheBadPartsPresentation,
  RqToc,
  React
};